import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Card, CardBody } from "reactstrap";
import { getUser } from "../../service/FetchUtil";


class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricings: [
        {
          id: 100,
          title: "Basic",
          price: "7.500",
          price2: "5.500",
          duration: "/Yıl",
          metin: "*Aralık ayı sonuna kadar",
          duration2: "/Ay",
          commission: true,
          buttonText: "Ücretsiz Deneyin",
          testid: "btn_temel",
          btnLink: "/register?p=100",
          isActive: true,
          isSale: true,
          features: [
            { title: "Stok/Hizmet Kartları" },
            { title: "Cari Takibi (Dövizli)" },
            { title: "Cari Ekstre Paylaşımı"},
            { title: "Kasa/Banka Takibi" },
            { title: "Nakit Akışı Tablosu"},
            { title: "Çek/Senet Takibi" },
            { title: "Personel Sicil/Kart" },
            { title: "Personel Masraf/Gider Takibi" },
            { title: "Gider/Masraf Takibi" },
            { title: "KDV Dökümü" },
            { title: "Alış ve Satış Fiyat Listeleri"},
            { title: "Depo Bazında Envanter"},
            { title: "Plasiyer Satış Takibi"},
            { title: "Gelen e-Fatura işleme"},
            { title: "e-Fatura/e-Arşiv" },
            { title: "500 e-kontör hediye", icon: "uil uil-gift" },
          ],
        },
        {
          id: 101,
          title: "Standard",
          price: "15.000,00",
          price2: "15.000",
          duration: "/Yıl",
          //metin: "* 7.210,00 ₺ (Net Satış Tutarı)",
          duration2: "/Ay",
          commission: true,
          buttonText: "Ücretsiz Deneyin",
          testid: "btn_eticaret",
          btnLink: "/register?p=101",
          features: [
            { title: "Şubeli Çalışma" },
            { title: "Sipariş Yönetimi" },
            { title: "Cari Anlaşmaları ve İskonto Takibi"},
            { title: "Maliyet Takibi"},
            { title: "Stok Devir Hızı Raporu"},
            { title: "Raf Ömrü Takibi"},
            { title: "Kritik Stok Seviyeleri Yönetimi"},
            { title: "İhracat,Mikro İhracat  (Opsiyonel)"},
            { title: "Araç/Saha satış depo işlemleri"},
            { title: "Masraf Merkezi (Opsiyonel)"},
            { title: "Personel Puantaj ve Bordro (Opsiyonel)"},
            { title: "Plasiyer Satış,Ciro,Tahsilat ve Prim Analizi"},
            { title: "Muhasebe Fişleri(Opsiyonel)"},
            { title: "e-Fatura(e-Arşiv)/e-SMM" },
            { title: "e-İrsaliye (Opsiyonel)" },
            { title: "Pazaryeri entegrasyonu(Opsiyonel)", icon: "uil uil-shopping-cart" },
            { title: "500 e-kontör hediye", icon: "uil uil-gift" },
          ],
        },
        {
          id: 102,
          title: "Professional",
          price: "",
          price2: "35.000",
          duration: "/Yıl",
          duration2: "/Ay",
          buttonText: "Ücretsiz Deneyin",
          testid: "btn_burdanbi",
          btnLink: "/register?p=102",
          features: [
            { title: "Cari Mutabakat" },
            { title: "Cari Yaşlandırma" },
            { title: "Seri, LOT/Parti Takibi" },
            { title: "Varyantlı Çalışma(Opsiyonel)" },
            { title: "Alınan Sipariş Süreç Yönetimi" },
            { title: "Proforma Fatura" },
            { title: "Fatura/İrsaliye Onay Süreçleri" },
            { title: "İhracat,Mikro İhracat  (Opsiyonel)"},
            { title: "Talep İşlemleri/Onay Süreçleri"},
            { title: "Depo Malzeme Yerleri takibi"},
            { title: "Banka Kredileri takibi"},
            { title: "Ticari Bilanço ve Gelir İzleme"},
            { title: "Personel Puantaj ve Bordro (Opsiyonel)"},
            { title: "Satın Alma Talepleri Yönetimi(Opsiyonel)"},
            { title: "Malzeme Ağacı,Üretim Fişi,Şahit Numune takibi"},
            { title: "Muhasebe Fişleri(Opsiyonel)"},
            { title: "e-Fatura(e-Arşiv)/e-SMM" },
            { title: "e-İrsaliye(Opsiyonel)" },
            { title: "Pazar Yeri Entegrasyonu (Opsiyonel)", icon: "uil uil-shopping-cart" },
            { title: "500 e-kontör hediye", icon: "uil uil-gift" },
          ],
        },
        {
          id: 103,
          title: "Enterprise",
          price: "60.000,00",
          price2: "60.000",
          duration: "/Lisans",
          duration2: "/Ay",
          buttonText: "Ücretsiz Deneyin",
          testid: "btn_burdanbi",
          btnLink: "/register?p=103",
          features: [
            { title: "Cari Mutabakat" },
            { title: "Cari Yaşlandırma" },
            { title: "Seri, LOT/Parti Takibi" },
            { title: "Varyantlı Çalışma" },
            { title: "Alınan Sipariş Süreç Yönetimi" },
            { title: "Proforma Fatura" },
            { title: "Fatura/İrsaliye Onay Süreçleri" },
            { title: "İhracat,Mikro İhracat  (Opsiyonel)"},
            { title: "Talep İşlemleri/Onay Süreçleri"},
            { title: "Depo Malzeme Yerleri takibi"},
            { title: "Banka Kredileri takibi"},
            { title: "Ticari Bilanço ve Gelir İzleme"},
            { title: "Personel Puantaj ve Bordro (Opsiyonel)"},
            { title: "Satın Alma Talepleri Yönetimi(Opsiyonel)"},
            { title: "Malzeme Ağacı,Üretim Fişi,Şahit Numune takibi"},
            { title: "Muhasebe Fişleri(Opsiyonel)"},
            { title: "e-Fatura(e-Arşiv)/e-SMM" },
            { title: "e-İrsaliye(Opsiyonel)" },
            { title: "Pazar Yeri Entegrasyonu (Opsiyonel)", icon: "uil uil-shopping-cart" },
            { title: "500 e-kontör hediye", icon: "uil uil-gift" },
          ],
        },
      ],
    };
  }
  render() {
    const user = getUser();
    return (
      <React.Fragment>
        {this.state.pricings.map((pricing, key) => (
          <Col
            name="pricing"
            xl="3"
            lg="3"
            md="3"
            sm="2"
            xs="12"
            key={key}
            className="mt-4 p-4"
          >
            <Card
              name="pricingbox"
              className={
                pricing.isActive
                  ? "pricing-rates business-rate shadow bg-white border-0"
                  : "pricing-rates business-rate shadow bg-white border-0"

              }
              style={{ borderRadius: '15px' }}

            >
              {pricing.isActive && (
                <div className="ribbon ribbon-right ribbon-warning overflow-hidden">
                  <span className="text-center d-block shadow small h6">
                    Kampanya
                  </span>
                </div>
              )}
              <CardBody >
                <h4
                  className={
                    pricing.isActive
                      ? "font-weight-bold text-primary mb-4"
                      : "font-weight-bold text-primary mb-4"
                  }
                >
                  {pricing.title}
                </h4>
                <div  className="d-flex mb-2" name="pricingsection" >
                  {pricing.isSale ? (
                    <del className="text-danger ms-2" style={{fontSize:20}}>
                      {pricing.price} <span>₺</span>
                    </del>
                  ) :null}
                </div>
                <div className="mb-3" name="pricingsection" style={{position:'relative',top:'-1em'}}>
                  <p className="ml-3 mb-0 text-danger font-weight-bold" style={{fontSize:12}}>{pricing.metin}</p>
                </div>
                <div className="d-flex mb-3" name="pricingsection"style={{position:'relative',top:'-1em'}}>
                  <span className="price h2 mb-0">{pricing.price2}</span>
                  <span className="h4 mb-0 mt-2">₺</span>
                  <span style={{ fontSize: "0.8em" }} className="align-self-end mb-1">
                    +KDV{pricing.duration}
                  </span>
                  {/*  <p style={{ fontSize: '11px' }} className="text-muted ml-8 mt-8">+KDV</p>*/}
                </div>
                <ul className="list-unstyled mb-0 pl-0">
                  {pricing.features.map((feature, key) => (
                    <li key={key} className="pricing-feature text-muted mb-0">
                      <span className="text-primary h5 mr-2">
                        <i className={(feature.icon || "uil uil-check-circle") + " align-middle"}></i>
                      </span>
                      {feature.title}
                    </li>
                  ))}
                </ul>
                {user && user.data ?
                  <Link to="/mytenants" testid={pricing.testid} style={{ fontSize: "0.8em" }} className="btn btn-secondary  mt-4" >
                    İşletmelerinizi inceleyin
                  </Link>

                  : (!user ?
                    <a href="/login" testid={pricing.testid} className="btn btn-info  mt-4" >
                      Giriş Yapın
                    </a>
                    :
                    <Link testid={pricing.testid} to={pricing.btnLink} className="btn btn-secondary  mt-4" >
                      {pricing.buttonText}
                    </Link>)}
              </CardBody>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default Pricing;
