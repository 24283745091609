import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
    Row,
    Col,
    Label,
    FormGroup,
    Input,
    Card,
    CardBody,
    Form,
    FormFeedback,
} from "reactstrap";
import Swal from "sweetalert2";


//Import Icons
import FeatherIcon from "feather-icons-react";
import Button from "../custom/button/Button";
import BaseViewComponent from "../custom/view/BaseViewComponent";
import { forgotpassmail } from '../../service/backend';
import { notifySuccess } from '../../components/custom/util/Util';
class ForgotPassword extends BaseViewComponent {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                isSendEmail: false
            }
        };
    }

    render() {
        return <div className="cover-user-img d-flex align-items-center">
            <Row>
                <Col xs={12}>
                    <Card
                        className="login-page border-0"
                        style={{ zIndex: "0" }}
                    >
                        <CardBody className="p-0">
                            <h4 className="card-title text-center">{this._tr("Şifremi Unuttum")}</h4>
                            <Form className="llogin-form mt-4 ml-3 password-form">
                                <Row>
                                    {this.state.model.isSendEmail &&
                                        <Col lg={12} className="password-form-col">
                                            <div id="pass">
                                                <p>Şifre Yenileme e-postası elinize ulaşmadıysa:</p>
                                                <ul>
                                                    <li>Şifre yenileme e-postasının gelmesi 10 dakikayı bulabilir.</li>
                                                    <li>Spam ve diğer e-posta klasörlerinizi kontrol ediniz.</li>
                                                    <li>Girdiğiniz e-posta adresinin doğruluğunu kontrol ediniz.</li>
                                                </ul>
                                            </div>
                                        </Col>
                                    }
                                    <Col lg={12}>
                                        <FormGroup className="position-relative">
                                            <Label htmlFor="email">
                                                E-Posta{" "}
                                                <span className="text-danger">*</span>
                                            </Label>
                                            <div className="position-relative">
                                                <i>
                                                    <FeatherIcon
                                                        icon="user"
                                                        className="fea icon-sm icons"
                                                    />
                                                </i>
                                            </div>
                                            <Input type="email" id="email" name="email" testid="login_txtEmail" value={this.state.model.email} onChange={this._handleTextChange} required
                                                placeholder="E-Posta adresiniz" ref={r => this.tbEmail = r} className="form-control pl-5"
                                                autoFocus={this.state.model.email == undefined || this.state.model.email == ""}
                                                invalid={this.state.model.email == undefined || this.state.model.email.trim().length < 5} />
                                            <FormFeedback>En az 5 karakter olmalıdır</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={12} className="mb-0">
                                        {!this.state.model.isSendEmail && <Button id="emailBtn" showBusy color="primary" block testid="emailBtn" onClick={this.sendPasswordMail}>
                                            Gönder
                                        </Button>}
                                        {this.state.model.isSendEmail && <Button id="emailBtn" color="success" block testid="closeBtn" onClick={(e) => window.location.href = "/login"}>
                                            Kapat
                                        </Button>}
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>;
    }

    sendPasswordMail = (event, args, resetBusy) => {
        const { model } = this.state;
        const that = this;
        if (model.email != undefined) {
            forgotpassmail(model.email, (resp) => {
                that._dirty_ = false;
                notifySuccess(model.email + " adresine mail başarıyla gönderilmiştir");
                model.isSendEmail = true;
                that.setState(model);
            }, null, resetBusy);
        } else {
            if (resetBusy) {
                resetBusy();
            }
            Swal.fire("Eksik bilgi", "E-mail alanı boş bırakılamaz", "error");
        }

    }

}
export default withTranslation()(ForgotPassword);